<template>
  <base-form
    v-slot="slotProps"
    :model="category"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Kategorie"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Hintergrundfarbe" prop="background_color">
      <el-color-picker
        v-model="slotProps.model.background_color"
      ></el-color-picker>
    </el-form-item>

    <image-upload-field
      current-label="Aktuelles Icon"
      label="Icon auswählen"
      :current-image="slotProps.model.icon_url"
      allowed-file-types="image/svg+xml"
      @file-chosen="setFile"
    />

    <p>
      Passendes Icon in unserer Datenbank:
      <router-link to="/icons">Hier</router-link>
    </p>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"
import ImageUploadField from "@/components/ImageUploadField"

export default {
  components: {
    BaseForm,
    ImageUploadField
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        background_color: [
          { required: true, message: "Bitte Farbe auswählen", trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    setFile(file) {
      this.category.icon = file
    }
  }
}
</script>
