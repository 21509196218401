import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/event_categories"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.event_categories
  },
  async get(categoryID) {
    let response = await Repository.get(`${resource}/${categoryID}`)
    return response.data.event_category
  },
  async create(categoryData) {
    const formData = new FormData()
    const entries = Object.entries(categoryData)
    for (const [key, value] of entries) {
      formData.append(`event_category[${key}]`, value ? value : "")
    }
    formData.append("region_id", store.state.auth.chosenRegion)
    let response = await Repository.post(`${resource}`, formData)
    return response.data
  },
  async update(categoryID, categoryData) {
    const formData = new FormData()
    const entries = Object.entries(categoryData)
    for (const [key, value] of entries) {
      formData.append(`event_category[${key}]`, value ? value : "")
    }
    let response = await Repository.patch(`${resource}/${categoryID}`, formData)
    return response.data
  },
  async destroy(categoryID) {
    let response = await Repository.delete(`${resource}/${categoryID}`)
    return response.data
  },
  async sort(categories) {
    let response = await Repository.patch(`${resource}/sort`, {
      event_categories: categories
    })
    return response.data
  }
}
