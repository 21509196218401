<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Kategorie">
      <event-category-form
        :category="category"
        submit-label="Neue Kategorie anlegen"
        :form-submitted="saveCategory"
        mode="new"
      ></event-category-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventCategoryForm from "@/components/forms/EventCategoryForm"
import EventCategoriesRepository from "@/repositories/event_categories_repository.js"

export default {
  metaInfo: {
    title: "Neue Kategorie"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    EventCategoryForm
  },
  data() {
    return {
      category: {}
    }
  },
  methods: {
    async saveCategory(category) {
      await EventCategoriesRepository.create(category)
      this.$router.push({ name: "EventCategoriesPage" })
    }
  }
}
</script>
